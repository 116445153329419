<template>
  <section class="m-merchant-index m-scroll" ref="merchantGoods"  @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="handleBack" src="@/assets/new-product/icon-fanhui@2x.png" alt="back">
      <div class="search-box">
        <img src="@/assets/public/icon_search@2x.png" alt="search">
        <input v-model="search" type="text" placeholder="请输入关键词" @change="goSearch()">
      </div>
      <router-link to="/" >
        <img src="@/assets/merchant/icon_home_3@2x.png" alt="img">
      </router-link>
    </div>
    <m-scroll-top :show="scrollTop" :dom="$refs.merchantGoods"></m-scroll-top>
    <div class="header-bg" :style="`opacity:${(100-this.offsetTop)/100};height:${swipeImgs.length == 0 ? 32 : nowIndex == 0 ? 65.06667 : 31.5 }vw;`"></div>
    <div class="merchant-info">
      <img :src="$store.state.merchant.pic_url" alt="merchant">
      <div class="center">
        <p>{{$store.state.merchant.title}}</p>
        <div>
          <div>
            <van-rate  v-model="$store.state.merchant.score" :size="12" color="#F03C18" void-icon="star" void-color="#ccc" readonly/>
          </div>
          <p>{{$store.state.merchant.collect_num}}人关注</p>
        </div>
      </div>
      <div class="followed" @click="handleFollowed()" v-if="$store.state.merchant.is_follow != null">
        <img src="@/assets/merchant/icon_guanzhu.png" alt="">
        <span>已关注</span>
      </div>
      <div class="followed" @click="handleFollowed()" v-else>
        <img src="@/assets/merchant/icon_guanzhu.png" alt="">
        <span>关注</span>
      </div>
    </div>
    <!-- 轮播 -->
    <van-swipe class="my-swipe" :autoplay="0" indicator-color="white" v-if="swipeImgs.length != 0 && nowIndex == 0">
      <van-swipe-item v-for="(img,index) in swipeImgs" :key="index" class="swipe-item">
        <img :src="img.pic_url" alt="" class="swipe-img">
      </van-swipe-item >
    </van-swipe>
    <!-- 列表数据 -->
    <div class="list-home" v-if="nowIndex == 0">
      <div class="goods-list">
        <div class="title">店铺热销</div>
        <goods-columns @cartClick="handleCart" :data="goodsNew" :loading="newLoad" :columns="2"></goods-columns>
      </div>
      <div class="goods-list">
        <div class="title">店铺上新</div>
        <goods-columns @cartClick="handleCart" :data="goodsHot" :loading="hotLoad" :columns="2"></goods-columns>
      </div>
    </div>
    <div v-else-if="nowIndex == 1">
      <!-- 推荐排序 -->
      <van-sticky offset-top="6.4vw">
        <div class="goods-filter">
          <div class="filter-item" :class="{'filter-active':tab == 1}" @click="handleTab(1)">推荐</div>
          <div class="filter-item" :class="{'filter-active':tab == 2}" @click="handleTab(2)">销量</div>
          <div class="filter-item" :class="{'filter-active':tab == 3}" @click="handleTab(3)">新品</div>
          <div class="filter-item" @click="handleTab(0)">
            <span :class="{'filter-active':tab == 4 || tab == 5}">价格</span>
            <img v-show="tab != 4 && tab != 5" src="@/assets/merchant/price (2).png" alt="">
            <img v-show="tab == 5" src="@/assets/merchant/price (3).png" alt="">
            <img v-show="tab == 4" src="@/assets/merchant/price (1).png" alt="">
          </div>
        </div>
      </van-sticky>
      <van-list  v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" ref="list">
        <goods-columns @cartClick="handleCart" :data="goodsList" :loading="goodsLoad" :columns="2"></goods-columns>
      </van-list> 
    </div>
    <!-- 分类数据 -->
    <div v-else>
      <div class="classify-item m-padding" v-for="(it, index) in options" :key="index">
        <div class="title">
          <span>{{it.title}}</span>
          <img src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
        <div class="items" >
          <router-link :to="{ path: '/zh/merchant/goodsList', query: { id:$route.params.id, tid: two.id, title:two.title   } }" v-for="(two, ind) in it.two_type" :key="ind">{{two.title}}</router-link>
        </div>
      </div>
    </div>
    <foot-bar @change="change"></foot-bar>
    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goods-id="goodsId"></m-sku>
    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>
    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'
import FootBar      from '@/components/zh/shops-footer.vue'
import MScrollTop   from '@/components/zh/m-scroll-top.vue'

import { info, goods, classify, goods_new, goods_hot, follow } from '@/api/zh/merchant.js'

export default {
  name:'MerchantIndex',
  components:{ GoodsColumns, MSku, MAnimate, CartFlex, FootBar, MScrollTop },
  data(){
    return {
      offsetTop: 0,
      tab: 1, 
      loading: false,
      finished: false,
      skuModal: false,
      start: false,
      start_dom: null,
      swipeImgs: [],
      goodsList: [],
      goodsLoad: false,
      goodsNew: [],
      newLoad: false,
      goodsHot: [],
      hotLoad: false,
      goodsId: '',
      search: '',
      followed: false,
      nowIndex: 0, // 底部导航索引 默认 0
      page: 1, 
      options: [], // 分类列表,
      scrollTop: false
    }
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop
      if(this.offsetTop < 100 && this.nowIndex == 1) {
        this.$refs.list.$el.style.marginTop = '0'
      }
      if(e.target.scrollTop >= 500){
        this.scrollTop = true
      }else{
        this.scrollTop = false
      }
    },
    handleBack(){
      this.$router.go(-1)
      this.nowIndex = 0
      sessionStorage.removeItem('nowIndex')
    },
    // 上拉加载
    onLoad(){
      this.page++
      this.getGoods()
    },
    change(i) {
      this.nowIndex = i
      this.$refs.merchantGoods.scrollTop = 0
    },
    handleTab(index) {
      this.finished = false
      this.page = 1
      this.goodsList = []
      this.$refs.merchantGoods.scrollTop = 100
      this.$refs.list.$el.style.marginTop = '55px'
      if(index == 0) {
        if([4,5].includes(this.tab)) {
          if(this.tab == 4) {
            this.tab = 5
          }else {
            this.tab = 4
          }
        } else {
          this.tab = 4
        }
      } else {
        this.tab = index 
      }
      this.getGoods()
    },
    // 获取店铺信息
    getInfo() {
      let params = { shops_id: this.$route.params.id}
      info(params).then(res =>{
        if(res) {
          this.$store.state.merchant = res.data
          this.swipeImgs = res.data.banner_list
        }
      })
    },
    // 获取 上新
    getNew() {
      let params = { shops_id: this.$route.params.id, status: 3 }
      this.newLoad = true
      goods_new(params).then(res => {
        if(res) {
          this.goodsNew = res.data.data
        }else{
          this.goodsNew = []
        }
      }).finally( () => {
        this.newLoad = false
      })
    },
    // 获取 热销
    getHot() {
      let params = { shops_id: this.$route.params.id, status: 2}
      this.hotLoad = true
      goods_hot(params).then(res => {
        if(res) {
          this.goodsHot = res.data.data
        } else {
          this.goodsHot = []
        }
      }).finally( () => {
        this.hotLoad = false
      })
    },
    // 获取商品
    getGoods() {
      let params = { shops_id: this.$route.params.id, status: this.tab, page: this.page }
      this.goodsLoad = true
      goods(params).then(res => {
        if(res) {
          this.goodsList = [...this.goodsList, ...res.data.data]
          if(this.page >= res.data.last_page) {
            this.finished = true
          }
        } else {
          this.goodsList = []
          this.finished = true
          this.loading = false
        }
      }).finally( () => {
        this.loading = false
      })
    },
    // 获取分类
    getClassify() {
      let params = { shops_id: this.$route.params.id, }
      classify(params).then(res => {
        if(res) {
          this.options = res.data
        }
      })
    },
    // 搜索
    goSearch() {
      if(this.search != '') {
        localStorage.setItem('back_path', this.$route.fullPath)
        this.$router.push( { path: '/zh/search', query: {s: 'merchant', key: this.search, id: this.$route.params.id} })
      }
    },
    // 点击关注
    handleFollowed() {
      let params = {
        status: this.$store.state.merchant.is_follow != null ? 0 : 1
      }
      follow(this.$route.params.id, params).then(res => {
        if(res) {
          if(this.$store.state.merchant.is_follow == null) {
            this.$store.state.merchant.collect_num ++
            this.$store.state.merchant.is_follow = 1
          } else {
            this.$store.state.merchant.collect_num --
            this.$store.state.merchant.is_follow = null
          }
          this.$notify({ type: 'success', message: res.msg })
        }
      })
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    }
  },
  created() {
    this.getInfo()
    this.getNew()
    this.getHot()
    this.getClassify()
    this.getGoods()
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.setItem('nowIndex', this.nowIndex)
    next()
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
