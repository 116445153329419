<template>
    <div class="footer-all" :style="{height: isAddTop ? '20vw' : '16vw'}">
        <div :class="nowIndex == 0 ? 'footer-item footer-scale' : 'footer-item'" @click="changeActive(0)">
            <div class="iconBox">
                <img src="@/assets/footer/m-index-1.png" alt="">
                <img src="@/assets/footer/m-index-2.png" alt="">
            </div>
            <p>首页</p>
        </div>
          <div :class="nowIndex == 1 ? 'footer-item footer-scale' : 'footer-item'" @click="changeActive(1)">
            <div class="iconBox">
                <img src="@/assets/footer/m-goods-1.png" alt="">
                <img src="@/assets/footer/m-goods-2.png" alt="">
            </div>
            <p>商品</p>
        </div>
        <div :class="nowIndex == 2 ? 'footer-item footer-scale' : 'footer-item'" @click="changeActive(2)">
            <div class="iconBox">
                <img src="@/assets/footer/m-class-1.png" alt="">
                <img src="@/assets/footer/m-class-2.png" alt="">
            </div>
            <p>分类</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAddTop: false,
            nowIndex: -1,
        }
    },
    methods: {
        isIPhoneX(){
            var u = navigator.userAgent;
            var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {    	
                if (screen.height == 812 && screen.width == 375){
                    return true
                }else{
                    return false
                } 
            }
        },
        isWeiXin(){
            var ua = window.navigator.userAgent.toLowerCase();
            if(ua.match(/MicroMessenger/i) == 'micromessenger'){
                return true;
            }else{
                return false;
            }
        },
        changeActive(index) {
            let obj = document.getElementsByClassName('footer-item')
            this.nowIndex = index
            // 首先添加缩放类
            let activeTimer = setTimeout(() => {
                // 去掉缩放 替换选中类
                obj[index].classList.remove('footer-scale')
                obj[index].classList.remove('footer-scale')
                obj[index].classList.add('footer-active')
                clearTimeout(activeTimer)
            }, 300)
            this.$emit('change',index)
        }
    },
    mounted() {
        if(this.isIPhoneX() && this.isWeiXin()) {
            this.isAddTop = true
        }
        if(sessionStorage.getItem('nowIndex')) {
            this.changeActive(sessionStorage.getItem('nowIndex'))
        } else {
            this.changeActive(0)
        }  
    }
}
</script>
<style scoped lang="less">
.footer-all{position: fixed;bottom:0;left:0;width:100%;height:80px;background-color: #f7f7f7;display: flex;justify-content: space-around;
padding:0 10px;z-index: 10;
    .footer-item{margin-top:5px;width:34px;height:48px;position: relative;transition: all 0.3s;
        img{width:34px;height:34px;position: relative;z-index: 2;transition: all 0.3s;position: absolute;top:0;left:0;object-fit: unset}
        img:nth-of-type(2){opacity: 0;}
        p{
            font-size:10px;
            
            font-weight:400;
            color:#000;
            text-align: center;
            margin-top:2px;
            line-height:18px;
            transition: color 0.3s
        }
    }
    .shop::after{
        width: 60px;
        height: 30px;
        content: "";
        left: -12px;
        top: -20px;
        position: absolute;
        display: block; 
        background: transparent;
        border-radius: 50%;
        background-color: #f7f7f7;
        z-index: 0;
    }
    .footer-active{
        p{color:#f04d2c}
        img{transform: scale(1, 1)}
        img:nth-of-type(1){opacity: 0;}
        img:nth-of-type(2){opacity: 1;}
    }
    .footer-scale{
        .iconBox{
            img{transform: scale(0.8, 0.8) !important}
        }
    }
    .iconBox{width:34px;height:34px;position: relative}
    .shop{
        .iconBox{width:30px;height:30px;margin:-5px 2px 0 2px}
    }
}
</style>